.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/tw-pfp-xmas-2024-1500w.webp");
  background-position: top;
}
.home-text {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  font-style: Black;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text16 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text16:hover {
  color: #ffdc00;
}
.home-text16:active {
  color: #73fba6;
}
.home-text17 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text17:hover {
  color: #ffdc00;
}
.home-text17:active {
  color: #73fba6;
}
.home-text18 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.home-text18:hover {
  color: #ffdc00;
}
.home-text18:active {
  color: #73fba6;
}
.home-text19 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.home-text19:hover {
  color: #ffdc00;
}
.home-text19:active {
  color: #73fba6;
}
.home-text20 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text20:hover {
  color: #ffdc00;
}
.home-text20:active {
  color: #73fba6;
}
.home-text21 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text21:hover {
  color: #ffdc00;
}
.home-text21:active {
  color: #73fba6;
}
.home-text22 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.home-text22:hover {
  color: #ffdc00;
}
.home-text22:active {
  color: #73fba6;
}
.home-text23 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text23:hover {
  color: #ffdc00;
}
.home-text23:active {
  color: #73fba6;
}
.home-text24 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  align-self: center;
  font-style: Black;
  text-align: center;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text24:hover {
  color: #ffdc00;
}
.home-text24:active {
  color: #73fba6;
}
@media(max-width: 991px) {
  .home-text {
    color: var(--dl-color-default-warmwhite);
    font-size: 80px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .home-text16 {
    font-size: 80px;
  }
  .home-text17 {
    font-size: 80px;
  }
  .home-text18 {
    font-size: 80px;
  }
  .home-text19 {
    color: var(--dl-color-default-warmwhite);
    font-size: 80px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .home-text20 {
    font-size: 80px;
  }
  .home-text21 {
    font-size: 80px;
  }
  .home-text22 {
    font-size: 80px;
  }
  .home-text23 {
    font-size: 80px;
  }
  .home-text24 {
    font-size: 80px;
  }
}
@media(max-width: 767px) {
  .home-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text {
    font-size: 50px;
  }
  .home-text16 {
    font-size: 50px;
  }
  .home-text17 {
    font-size: 50px;
  }
  .home-text18 {
    font-size: 50px;
  }
  .home-text19 {
    font-size: 50px;
  }
  .home-text20 {
    font-size: 50px;
  }
  .home-text21 {
    font-size: 50px;
  }
  .home-text22 {
    font-size: 50px;
  }
  .home-text23 {
    font-size: 50px;
  }
  .home-text24 {
    font-size: 50px;
  }
}
@media(max-width: 427px) {
  .home-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .home-text {
    color: var(--dl-color-default-warmwhite);
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .home-text16 {
    font-size: 30px;
  }
  .home-text17 {
    font-size: 30px;
  }
  .home-text18 {
    font-size: 30px;
  }
  .home-text19 {
    font-size: 30px;
  }
  .home-text20 {
    font-size: 30px;
  }
  .home-text21 {
    font-size: 30px;
  }
  .home-text22 {
    font-size: 30px;
  }
  .home-text23 {
    font-size: 30px;
  }
  .home-text24 {
    font-size: 30px;
  }
}

.resume-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/gradientbg-1500w.webp");
  background-repeat: repeat;
  background-position: top;
}
.resume-faq7 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.resume-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.resume-text {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 100px;
  font-style: Black;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-list-item1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text005 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text005:hover {
  color: #ffdc00;
}
.resume-text005:active {
  color: #73fba6;
}
.resume-text007 {
  text-transform: lowercase;
}
.resume-text009 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text013 {
  text-transform: lowercase;
}
.resume-list-item2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text016 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text016:hover {
  color: #ffdc00;
}
.resume-text016:active {
  color: #73fba6;
}
.resume-text017 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-list-item3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text024 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text024:hover {
  color: #ffdc00;
}
.resume-text024:active {
  color: #73fba6;
}
.resume-text025 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text032 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text032:hover {
  color: #ffdc00;
}
.resume-text032:active {
  color: #73fba6;
}
.resume-text035 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text035:hover {
  color: #ffdc00;
}
.resume-text035:active {
  color: #73fba6;
}
.resume-list-item4 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text038 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text038:hover {
  color: #ffdc00;
}
.resume-text038:active {
  color: #73fba6;
}
.resume-text039 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text047 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text047:hover {
  color: #ffdc00;
}
.resume-text047:active {
  color: #73fba6;
}
.resume-text050 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text050:hover {
  color: #ffdc00;
}
.resume-text050:active {
  color: #73fba6;
}
.resume-text053 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text053:hover {
  color: #ffdc00;
}
.resume-text053:active {
  color: #73fba6;
}
.resume-text056 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text056:hover {
  color: #ffdc00;
}
.resume-text056:active {
  color: #73fba6;
}
.resume-text059 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text059:hover {
  color: #ffdc00;
}
.resume-text059:active {
  color: #73fba6;
}
.resume-text062 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text062:hover {
  color: #ffdc00;
}
.resume-text062:active {
  color: #73fba6;
}
.resume-text065 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-list-item5 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text068 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text068:hover {
  color: #ffdc00;
}
.resume-text068:active {
  color: #73fba6;
}
.resume-text069 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text077 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text077:hover {
  color: #ffdc00;
}
.resume-text077:active {
  color: #73fba6;
}
.resume-list-item6 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text080 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text080:hover {
  color: #ffdc00;
}
.resume-text080:active {
  color: #73fba6;
}
.resume-text081 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
.resume-text085 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text085:hover {
  color: #ffdc00;
}
.resume-text085:active {
  color: #73fba6;
}
.resume-list-item61 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.resume-text088 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 60px;
  font-style: Black;
  text-align: left;
  transition: 0.3s;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
}
.resume-text088:hover {
  color: #ffdc00;
}
.resume-text088:active {
  color: #73fba6;
}
.resume-text089 {
  color: var(--dl-color-default-warmwhite);
  height: auto;
  font-size: 24px;
  font-style: Black;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 900;
  line-height: normal;
  font-stretch: normal;
  mix-blend-mode: difference;
  text-transform: uppercase;
  text-decoration: none;
}
@media(max-width: 991px) {
  .resume-container {
    background-position: top;
  }
  .resume-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .resume-text {
    color: var(--dl-color-default-warmwhite);
    font-size: 80px;
  }
  .resume-text001 {
    font-size: 100px;
  }
  .resume-text002 {
    font-size: 100px;
  }
  .resume-text003 {
    font-size: 100px;
  }
  .resume-text005 {
    font-size: 60px;
  }
  .resume-text009 {
    color: var(--dl-color-default-warmwhite);
  }
  .resume-text013 {
    text-transform: lowercase;
  }
  .resume-text016 {
    font-size: 60px;
  }
  .resume-text017 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text024 {
    color: var(--dl-color-default-warmwhite);
    font-size: 60px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .resume-text025 {
    color: var(--dl-color-default-warmwhite);
  }
  .resume-text032 {
    font-size: 24px;
  }
  .resume-text035 {
    font-size: 24px;
  }
  .resume-text038 {
    font-size: 60px;
  }
  .resume-text039 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text047 {
    font-size: 24px;
  }
  .resume-text050 {
    font-size: 24px;
  }
  .resume-text053 {
    font-size: 24px;
  }
  .resume-text056 {
    font-size: 24px;
  }
  .resume-text059 {
    font-size: 24px;
  }
  .resume-text062 {
    font-size: 24px;
  }
  .resume-text065 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text068 {
    font-size: 60px;
  }
  .resume-text069 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text077 {
    font-size: 24px;
  }
  .resume-text080 {
    font-size: 60px;
  }
  .resume-text081 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text085 {
    font-size: 24px;
  }
  .resume-text088 {
    font-size: 60px;
  }
  .resume-text089 {
    color: var(--dl-color-default-warmwhite);
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .resume-container {
    background-position: top;
  }
  .resume-text {
    color: var(--dl-color-default-warmwhite);
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text001 {
    font-size: 100px;
  }
  .resume-text002 {
    font-size: 100px;
  }
  .resume-text003 {
    font-size: 100px;
  }
  .resume-list {
    gap: var(--dl-space-space-twounits);
  }
  .resume-text005 {
    font-size: 50px;
  }
  .resume-text009 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text013 {
    text-transform: lowercase;
  }
  .resume-text016 {
    font-size: 50px;
  }
  .resume-text017 {
    font-size: 20px;
  }
  .resume-text024 {
    font-size: 50px;
  }
  .resume-text025 {
    font-size: 20px;
  }
  .resume-text032 {
    font-size: 20px;
  }
  .resume-text035 {
    font-size: 20px;
  }
  .resume-text038 {
    font-size: 50px;
  }
  .resume-text039 {
    font-size: 20px;
  }
  .resume-text047 {
    font-size: 20px;
  }
  .resume-text050 {
    font-size: 20px;
  }
  .resume-text053 {
    font-size: 20px;
  }
  .resume-text056 {
    font-size: 20px;
  }
  .resume-text059 {
    font-size: 20px;
  }
  .resume-text062 {
    font-size: 20px;
  }
  .resume-text065 {
    font-size: 20px;
  }
  .resume-text068 {
    font-size: 50px;
  }
  .resume-text069 {
    font-size: 20px;
  }
  .resume-text077 {
    font-size: 20px;
  }
  .resume-text080 {
    font-size: 50px;
  }
  .resume-text081 {
    font-size: 20px;
  }
  .resume-text085 {
    font-size: 20px;
  }
  .resume-text088 {
    font-size: 50px;
  }
  .resume-text089 {
    font-size: 20px;
  }
}
@media(max-width: 427px) {
  .resume-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .resume-section-title {
    width: auto;
    position: relative;
    flex-direction: column;
  }
  .resume-text {
    color: var(--dl-color-default-warmwhite);
    width: auto;
    font-size: 60px;
    text-align: center;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text001 {
    font-size: 60px;
  }
  .resume-text002 {
    font-size: 60px;
  }
  .resume-text003 {
    font-size: 60px;
  }
  .resume-text005 {
    color: var(--dl-color-default-warmwhite);
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .resume-text007 {
    text-transform: lowercase;
  }
  .resume-text009 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text013 {
    text-transform: lowercase;
  }
  .resume-text016 {
    color: var(--dl-color-default-warmwhite);
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text017 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text024 {
    font-size: 30px;
  }
  .resume-text025 {
    color: var(--dl-color-default-warmwhite);
  }
  .resume-text032 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .resume-text035 {
    font-size: 18px;
  }
  .resume-text038 {
    font-size: 30px;
  }
  .resume-text039 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text047 {
    font-size: 18px;
  }
  .resume-text050 {
    font-size: 18px;
  }
  .resume-text053 {
    font-size: 18px;
  }
  .resume-text056 {
    font-size: 18px;
  }
  .resume-text059 {
    font-size: 18px;
  }
  .resume-text062 {
    font-size: 18px;
  }
  .resume-text065 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text068 {
    font-size: 30px;
  }
  .resume-text069 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text077 {
    font-size: 18px;
  }
  .resume-text080 {
    font-size: 30px;
  }
  .resume-text081 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  .resume-text085 {
    font-size: 18px;
  }
  .resume-text088 {
    font-size: 30px;
  }
  .resume-text089 {
    color: var(--dl-color-default-warmwhite);
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
}
